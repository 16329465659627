import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { FeatureFlagService } from '../providers/feature-flag.service';
import { FeatureFlagSettings } from '../feature-flag.types';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard  {
    constructor(
        private featureFlagService: FeatureFlagService,
        private router: Router
    ) {}
    canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
        const featureFlagSettings: FeatureFlagSettings = route.data['featureFlagSettings'];
        const requiredFeatureFlag = featureFlagSettings['requiredFeatureFlag'];
        const redirectRoute = featureFlagSettings['redirectRoute'] || '/';
        const isFeatureEnabled = this.featureFlagService.isFeatureEnabled(requiredFeatureFlag);
        const redirectionUrl = this.router.createUrlTree([redirectRoute]);

        if (featureFlagSettings.redirectWhenFeatureIsOn) {
            return isFeatureEnabled ? redirectionUrl : true;
        } else {
            return isFeatureEnabled ? true : redirectionUrl;
        }
    }
  
}
